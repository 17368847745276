import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { SHIPPING_INFO_URL } from '../../constants';

@Component({
  selector: 'app-return-policy-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './return-policy-link.component.html',
  styleUrls: ['./return-policy-link.component.scss'],
})
export class ReturnPolicyLinkComponent extends LocalizedComponent {
  public dynamicPricingSectionUrl = `${SHIPPING_INFO_URL}#dynamic-pricing`;

  @Input() amount: number;
}
