<div class="policy-container" *ngIf="amount > 0">
  <img src="assets/img/discount-icon.svg" />
  <p class="caption2--regular">
    {{
      trans('DYNAMIC_PRICING.POLICY_HINT.TITLE', {
        amount
      })
    }}
    <a [href]="dynamicPricingSectionUrl" class="caption2--bold" target="_blank">
      {{ trans('DYNAMIC_PRICING.POLICY_HINT.LINK') }}
    </a>
  </p>
</div>
